import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import PostPreview from "../post-preview/post-preview.component";

import "./posts-list.styles.scss";

const PostsList = ({ nodes }) => {
  const { site } = useStaticQuery(query);

  const initialSlice =
    nodes.length <= site.siteMetadata.news.pagination.init
      ? nodes.length
      : site.siteMetadata.news.pagination.init;

  const [list, setList] = useState([...nodes.slice(0, initialSlice)]);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(
    nodes.length > site.siteMetadata.news.pagination.init
  );

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const isMore = list.length < nodes.length;
      const nextResults = isMore
        ? nodes.slice(
            list.length,
            list.length + (site.siteMetadata.news.pagination.step || 3)
          )
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore, nodes, list, site.siteMetadata.news.pagination.step]);

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < nodes.length;
    setHasMore(isMore);
  }, [list, nodes]);

  return (
    <>
      {list && list.map((node) => <PostPreview key={node.id} node={node} />)}

      {hasMore ? (
        <button
          className="button is-link is-light is-fullwidth mt-6"
          onClick={handleLoadMore}
        >
          Load More
        </button>
      ) : (
        <p className="is-hidden">Nessun altro post</p>
      )}
    </>
  );
};

export default PostsList;

const query = graphql`
  query NewsPaginationData {
    site {
      siteMetadata {
        news {
          pagination {
            init
            step
          }
        }
      }
    }
  }
`;
