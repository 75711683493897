import React from "react";

const GraphQLErrorList = ({ errors }) => (
  <divv>
    <h1>Error</h1>
    {errors.map((error) => (
      <pre key={error.message}>{error.message}</pre>
    ))}
  </divv>
);

export default GraphQLErrorList;
