import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import PostsList from "../../components/posts-list/posts-list.component";
import GraphQLErrorList from "../../components/graphql-error-list";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../../lib/helpers";

import Seo from "../../components/seo";
import NewsLetter from "../../components/news-letter/news-letter.component";

import "./index.styles.scss";

export const query = graphql`
  query Posts {
    posts: allSanityPost(
      # limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          summary
          slug {
            current
          }
          categories {
            title
          }
          mainImage {
            asset {
              url
              gatsbyImageData(width: 320, height: 200, placeholder: BLURRED)
            }
          }
          author {
            name
            image {
              asset {
                gatsbyImageData(width: 48, aspectRatio: 1, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

const News = ({ data, errors }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <Layout pageClass="news">
      <Seo title="News" />
      <section className="hero header is-large">
        <div className="hero-body">
          <div className="container is-max-desktop">
            <h1 className="title is-1 has-text-white has-text-centered mb-6 is-capitalized">
              Ultime notizie
            </h1>
          </div>
        </div>
      </section>
      <section className="section is-medium">
        <div className="container is-max-desktop">
          <PostsList nodes={postNodes} />
        </div>
      </section>
      <NewsLetter />
    </Layout>
  );
};

export default News;
