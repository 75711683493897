import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { respH } from "../../lib/helpers.js";

import "./post-preview.styles.scss";

const PostPreview = ({ node, options = {} }) => {
  const newOptions = {
    author: true,
    shadows: true,
    ...options,
  };

  const dateFolder = format(new Date(node.publishedAt), "yyyy/MM");

  return (
    <Link
      className="block is-block"
      to={`/news/${dateFolder}/${node.slug.current}`}
    >
      <div className="card columns is-shadowless">
        <div className="card-image column is-one-third">
          <GatsbyImage
            image={node.mainImage.asset.gatsbyImageData}
            alt="image post"
          />
        </div>
        <div className="card-content column">
          <div className="media">
            {newOptions.author && (
              <div className="media-left">
                <GatsbyImage
                  imgClassName="has-rounded-corners"
                  image={node.author.image.asset.gatsbyImageData}
                  alt="image autore"
                />
              </div>
            )}
            <div className="media-content">
              <p className="subtitle is-6 is-capitalized">
                {node.categories &&
                  node.categories.map((cat, i) => (
                    <span key={i} className="is-size-6">
                      #{cat.title}{" "}
                    </span>
                  ))}{" "}
                |{" "}
                {format(new Date(node.publishedAt), "d, MMMM, yyyy", {
                  locale: it,
                })}
              </p>
              <p className={`title ${respH(4)} is-capitalized`}>{node.title}</p>
            </div>
          </div>

          <div className="content">
            <p>{node.summary}</p>
            {/* <PortableText value={node._rawBody} components={components}/> */}
            {/* <pre>{JSON.stringify(node.summary, null, 2)}</pre> */}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PostPreview;
