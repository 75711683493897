import React from "react";
import { FaMailchimp } from "react-icons/fa";
import { respH } from "../../lib/helpers";

import "./news-letter.styles.scss";

const NewsLetter = () => {
  return (
    <section className="hero is-medium">
      <div className="hero-body has-text-centered">
        <div className="container is-max-desktop">
          <p className={`title ${respH(1)} mb-6`}>
            Vuoi rimanere aggiornato sui nostri corsi?
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://eepurl.com/h2J6nH"
            className="button is-medium is-rounded"
          >
            <FaMailchimp className="mr-3" /> Iscriviti alla newsletter
          </a>
        </div>
      </div>
    </section>
  );
};

export default NewsLetter;
